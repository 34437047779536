import React from "react"

import USGraphic from "../../assets/img/graphics/us.svg"
import { Fade, Grid, Typography } from "@material-ui/core"
import {  makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(2),
  },
  uSGraphic: {
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "40px",
    lineHeight: "47px",
  },
  grid: {},
}));

export default function AboutUsSection() {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.grid}
    >
      <Grid item sm={5}>
        <Typography color="primary" className={classes.title} variant="h2">
          Sobre Nós
        </Typography>
        <Typography
          align="justify"
          className={classes.description}
          variant="body1"
        >
          Como um parceiro tecnológico nos comprometemos em servir pessoas individuais e coletivas contribuindo para a conexão e o bem estar de todos, criando oportunidades através de soluções inovadoras e disruptivas.
        </Typography>
        <Typography
          align="justify"
          className={classes.description}
          variant="body1"
        >
          A nossa visão é desenvolver produtos e serviços de alta qualidade, baixo custo e de extrema utilidade para os nossos clientes. E liderar o mercado das novas tecnologias em Cabo Verde.
        </Typography>
        <Typography
          align="justify"
          className={classes.description}
          variant="body1"
        >
          Preservamos os valores de integridade, honestidade, respeito e empenho para contribuir com o desenvolvimento e satisfação dos nossos parceiros, reconhecendo a nossa responsabilidade social e ambiental. Apostando na simplicidade e automação das atividades e processos repetitivos no desenho e concepção dos nossos serviços.
        </Typography>
      </Grid>
      <Grid item sm={1} />
      <Grid item sm={6}>
        <Fade timeout={800} in={true}>
          <img className={classes.uSGraphic} src={USGraphic} alt="us" />
        </Fade>
      </Grid>
    </Grid>
  );
}
