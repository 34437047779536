import Landing from "./landing";
import Survey from "./survey";
import Privacy from "./privacy";

export {
    Landing,
    Privacy,
    Survey
};

export default [
    Landing,
    Privacy,
    Survey
]