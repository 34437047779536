import React, { useContext } from "react";
import { Container, Fade,Button, Box } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "./banner";
import EmailInput from "./newsletter/emailInput";

import Team from "./team";
import AboutUs from "./aboutUs";
import HowCanWeHelp from "./howCanWeHelp";
import Product from "./produt";
import AppContext, { AppProvider } from "../../AppContext";
import IslandsGraphic from "../../assets/img/graphics/islands.svg";
import BannerShapeGraphic from "../../assets/img/graphics/round-shape.svg";
import BannerShapeGraphic2 from "../../assets/img/graphics/round-shape2.svg";
import BannerShapeGraphic3 from "../../assets/img/graphics/round-shape3.svg";
import BannerShapeGraphic4 from "../../assets/img/graphics/round-shape4.svg";
import BannerShapeGraphic5 from "../../assets/img/graphics/round-shape5.svg";
import BannerShapeGraphic7 from "../../assets/img/graphics/round-shape7.svg";

const useStyles = makeStyles((theme) => ({
  container: {},
  link:{
    textDecoration:"none"
  },
  button: {
    background:
      "linear-gradient(59.04deg, #65C299 -0.35%, #65BE99 12.46%, #62B59D 27.88%, #5EA8A2 44.64%, #5A94A7 62.34%, #557CA9 80.8%, #4F60AC 99.65%)",
    border: 0,
    borderRadius: 100,
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 50,
    width: 152,
    lineHeight: "19px",
  },
  sections: {
    "& section": {
      position: "relative",
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
        minHeight: "100vh",
        display: "flex",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(10),
      },
    },
    "& #launcher":{
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(10),
      },
    },
    "& #howCanWeHelp":{
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(8),
      },
    },
    "& #aboutUs":{
      [theme.breakpoints.up(1700)]: {
        minHeight: "30vh",
      },
    },
  },
  islandsGraphic: {
    position: "absolute",
    opacity: "0.3 !important",
    top: 80,
    left: 80,
    zIndex: -1,
    width: "40%",
    [theme.breakpoints.up(1700)]: {
      width: "47%",
      left: 60,
      top: 120,
    },
    [theme.breakpoints.down("sm")]: {
      top: "70px",
      left: "120px",
      width: "75%",
    },
    [theme.breakpoints.down("xs")]: {
      top: "100px",
      left: "10px",
      width: "87%",
    },
  },
  bannerShapeGraphic: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    height: "110%",
    [theme.breakpoints.down("sm")]: {
      top: "-210px",
      left: "-63px",
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      top: "-300px",
      left: "-280px",
      height: "auto",
    },
  },
  bannerShapeGraphic2: {
    position: "absolute",
    left: 0,
    top: "-5%",
    width: "30vw",
    [theme.breakpoints.down("sm")]: {
      width: "83vw",
      marginLeft: "-8em",
      height: "100%",
    },
    // [theme.breakpoints.up("lg")]: {
      
    // },
    // [theme.breakpoints.up("xl")]: {
    //   marginTop: "63em",
    //   width: "35vw",
    // },
    zIndex: -1,
  },
  bannerShapeGraphic3: {
    overflow: "hidden",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      width: "53vw",
    },
    [theme.breakpoints.up("md")]: {
      top: "-420px",
      width: "57vw",
    },
    // [theme.breakpoints.up("xl")]: {
    //   marginTop: "25em",
    //   width: "65vw",
    // },
  },
  bannerShapeGraphic4: {
    overflow: "hidden",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: -1,
    width: "45vw",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "50vw",
    },
    [theme.breakpoints.up("xl")]: {
    },
  },
  bannerShapeGraphic5: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
    marginTop: "115em",
    width: "6vw",
    [theme.breakpoints.down("sm")]: {
      marginTop: "160em",
      marginRight: "-2em",
      width: "18vw",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "135em",
      width: "5vw",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "135em",
      width: "8vw",
    },
  },
  bannerShapeGraphic7: {
    background: `url(${BannerShapeGraphic7}) no-repeat center center`,
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "170px",
    [theme.breakpoints.down("sm")]: {},
    bottom: 0,
    zIndex: 0,
  },
}));
/*alert(
  JSON.stringify({
    height: window.innerHeight,
    width: window.innerWidth,
  })
);*/
const Landing = () => {
    const classes = useStyles();
  const { subscribe, logEvent } = useContext(AppContext);

  const EmailComponent = ()=> <EmailInput subscribe={subscribe} logEvent={logEvent} />;
    
    return (
      <Fade timeout={800} in={true}>
        <div className={classes.sections}>
          <section id="launcher">
            <Fade timeout={800} in={true}>
              <img
                className={classes.bannerShapeGraphic}
                src={BannerShapeGraphic}
                alt="Launch"
              />
            </Fade>
            <Fade timeout={800} in={true}>
              <img
                className={classes.islandsGraphic}
                src={IslandsGraphic}
                alt="Islands"
              />
            </Fade>
            <Container>
              <Banner EmailInput={EmailComponent}/>
            </Container>
          </section>
          <section id="howCanWeHelp">
            <Fade timeout={800} in={true}>
              <img
                className={classes.bannerShapeGraphic2}
                src={BannerShapeGraphic2}
                alt="Launch"
              />
            </Fade>
            <Container>
              <HowCanWeHelp />
              <Box>
                <Product />
              </Box>
            </Container>
            <Fade timeout={800} in={true}>
              <img
                className={classes.bannerShapeGraphic3}
                src={BannerShapeGraphic3}
                alt="Launch"
              />
            </Fade>
          </section>
          <section id="aboutUs">
            <Fade timeout={800} in={true}>
              <img
                className={classes.bannerShapeGraphic4}
                src={BannerShapeGraphic4}
                alt="Launch"
              />
            </Fade>
            <Container>
              <AboutUs />
            </Container>
          </section>
          <section id="team">
            <Container>
              <Team />
            </Container>
          </section>
          <div className={classes.bannerShapeGraphic7} />
        </div>
      </Fade>
    );
};
export default {
  title: "Landing Page",
  path: "/",
  isNav: true,
  Container: () => (
    <AppProvider>
      <Landing />
    </AppProvider>
  ),
};

