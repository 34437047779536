// @flow
import React, {
  createContext,
  useState
} from "react";

import surveyData from './surveyData'

const defaultSurveyContext = {
  surveyData,
  answer: null
};

export const SurveyContext = createContext(defaultSurveyContext);

export default ({children}) => {
  const [state] = useState(defaultSurveyContext);
  return ( 
    <SurveyContext.Provider value = {{...state}}>
      {children} 
    </SurveyContext.Provider>
  );
}
