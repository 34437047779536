import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

const config = {
    apiKey: "AIzaSyDu1nohHhFpTwkkVTz8UGPKKLXXQgjsEXE",
    authDomain: "nanoz-cv.firebaseapp.com",
    databaseURL: "https://nanoz-cv.firebaseio.com",
    projectId: "nanoz-cv",
    storageBucket: "nanoz-cv.appspot.com",
    messagingSenderId: "813604844947",
    appId: "1:813604844947:web:c0fc2b56c0cf11d55d6466",
    measurementId: "G-B686330X4Y",
};

// Initialize Firebase
firebase.initializeApp(config);
firebase.analytics();
firebase.performance();

//firebase.functions().useFunctionsEmulator("http://localhost:5001");
firebase.analytics().setUserProperties({
    height: window.screen.height,
    width: window.screen.width,
    orientation: window.screen.orientation.type,
    language: navigator.language
})
const fireFunction =  (functionName, data) => {
    const trace = firebase.performance().trace(functionName);
    trace.start();
    return firebase.functions().httpsCallable(functionName)(data).finally(() => trace.stop());
};

const logEvent = async (eventName,data)=>{
    await firebase.analytics().logEvent(eventName, data)
}
export {
    firebase,
    fireFunction,
    logEvent
}