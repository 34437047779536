import React from "react";
import Survey from "../../components/Survey";
import {Box, Typography} from '@material-ui/core';
export default function SurveyComponent({
  json,
}) {
  
  const onComplete = (result) => console.log("Complete! " + result);
  const onValueChanged = (result) => console.log("Changed! " + result);
  json.showCompletedPage  = false;
  
  return (
    <Box minHeight="100vh">
      <Survey
        json={json}
        onComplete={onComplete}
        onValueChanged={onValueChanged}
      />
    </Box>
  );
}
