import React from "react";
import "./App.css";
import CONTAINERS, { Landing } from "./pages";
import { AppShell, NavBar, Footer } from "./components";
import theme from "./theme";
import logo from "./assets/img/logo.svg";
import logoWhite from "./assets/img/logo-white.svg";
//import FullLoader from './components/FullLoader';
//import IsLoadingHOC from "./components/IsLoadingHOC";
const navBar = ({ triggers, isNav, islogoWhite }) => {
  return <NavBar triggers={triggers} isNav={isNav} logo={logo} logoWhite={logoWhite} islogoWhite={islogoWhite} />;
};
const footer = <Footer logo={logoWhite} />;
//const LoaderComponent = (props) => <FullLoader logo={logo}  {...props}/>;

function App() {
  return (
    <AppShell
      rootRoute={Landing.path}
      containers={CONTAINERS}
      theme={theme}
      NavBar={navBar}
      footer={footer}
    />
  );
}

export default App;
