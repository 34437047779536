import React from "react"

import { Grid, Typography,Box } from "@material-ui/core"
import {  makeStyles } from "@material-ui/core/styles"
import FeaturesList from './featuresList'

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(4),
  },
  productGraphic: {
    
  },
  box: {
    marginTop: "10vh",
      [theme.breakpoints.up("md")]: {
        marginTop: "15%",
      }, 
  },
}));

export default function ProductSection() {
  
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.grid}
    >
      <Grid item sm={9}>
        <Box className={classes.box}>
          <Typography align="center" color="primary" variant="h2">
            Construindo o aplicativo com as funcionalidades que todos amam.
          </Typography>
          <Box>
            <FeaturesList />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
