import React from "react"
import LaunchGraphic from "../../assets/img/graphics/launch.svg"
import { Grid, Typography, Box, Fade } from "@material-ui/core";
import {  makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(4),
    color: "white",
  },
  launchGraphic: {
    width: "100%",
  },
  grid: {
    [theme.breakpoints.up("md")]: {
      minHeight: "100vh",
    },
  },
  title: {
    color: "white",
    alignItems: "center",
  },
}));

export default function BannerSection({ EmailInput}) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={8}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.grid}
    >
      <Grid item md={6} sm={9} xs={12}>
        <Typography align="left" className={classes.title} variant="h1">
          Ten diferensa di kapasidadi entri nos, mas nos tudo kre oportunidadi igual.
        </Typography>
        <Typography
          align="justify"
          className={classes.description}
          variant="body1"
        >
          Estamos a construir uma nova forma de interação entre os que precisam de vender e comprar produtos e serviços em Cabo Verde. Não perca esta oportunidade e junte-se à nós.
        </Typography>
        <Box display="flex" mt={4}>
          <EmailInput />
        </Box>
      </Grid>
      <Grid item md={6} sm={9} xs={12}>
        <Fade timeout={800} in={true}>
          <img
            className={classes.launchGraphic}
            src={LaunchGraphic}
            alt="Launch"
          />
        </Fade>
      </Grid>
    </Grid>
  );
}
