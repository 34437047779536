import React from "react"
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactTypingEffect from 'react-typing-effect';

const useStyles = makeStyles((theme) => ({
  description: {},
  launchGraphic: {
    width: "100%",
  },
  grid: {
    minHeight: "100vh",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    alignItems: "center",
  },
  logo: {
    height: theme.spacing(15),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(10),
    },
  },
}));

export default function FullLoaderSection({ logo }) {
  const classes = useStyles();
  console.log({ logo });
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.grid}
    >
      <Grid item xs={8}>
        <Typography align="center" className={classes.title} variant="h6">
          <ReactTypingEffect
            text={[
              "Noz tudu nu tem kapasidadiz diferenti...",
              "Ma noz tudu kre oportunidadiz igual...",
            ]}
            typingDelay={300}
            speed={50}
            eraseDelay={10}
          />
        </Typography>
        <Box mt={2} textAlign="center">
          <img className={classes.logo} src={logo} alt="logo" />
        </Box>
      </Grid>
    </Grid>
  );
}
