import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FotoAdemir from "../../../assets/img/team/Ademir.png";
import FotoRicardo from "../../../assets/img/team/Ricardo.png";
import FotoWilson from "../../../assets/img/team/Wilson.png";
import { Typography, Paper, Box, Fade } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: theme.spacing(50),
    width: "100%",
    boxShadow:"none",
    borderRadius:0,
  },
  control: {
    padding: theme.spacing(2),
  },
  teamPhoto: {
    height: "100%",
  },
  teamName: {
    textAlign: "left",
  },
  teamRole: {
    textAlign: "left",
  },
  teamDescription: {
    textAlign: "justify",
    color: "#979797",
  },
}));
export default function SpacingGrid() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} justify="center" spacing={3}>
      {[
        {
          name: "Ademir Lopes",
          description:
            "Apaixonado pelas tecnologias e inovação. Adora Fotografia e Vídeo, Design de Experiência (UX) e design de Interfaces (UI). Proativo e focado em resultados, tem gosto por enfrentar desafios. É comprometido em entregar designs únicos e criativos para os seus projetos. Podem sempre contar com o seu entusiasmo e criatividade.",
          photo: FotoAdemir,
          role: "Fundador",
        },
        {
          name: "Ricardo da Luz",
          description:
            "Formado em Engenharia de Sistemas e Informática, na variante multimédia, já acumula muita experiência na área de design gráfico, produção e edição de vídeos. Empenhado com o trabalho em equipa de forma a garantir uma boa qualidade como resultado final. Está sempre por dentro das novas tendências de design e aberto a novos desafios.",
          photo: FotoRicardo,
          role: "Fundador",
        },
        {
          name: "Wilson Sacrossantos",
          description:
            "Estudou Engenharia de Software, e ao longo dos anos foi adquirindo capacidades no desenvolvimento de soluções de software ligados ao e-commerce, serviços bancários e soluções e-gov. Gosta de resolver problemas, participar em equipas de trabalho e recorre à automação de atividades recorrentes para acelerar e otimizar o trabalho.",
          photo: FotoWilson,
          role: "Fundador",
        },
      ].map((team, key) => (
        <Grid xs={12} md={4} key={key} item>
          <Fade timeout={800} in={true}>
            <Paper
              elevation={1}
              className={classes.paper}
              style={{
                background: `url(${team["photo"]}) no-repeat center center`,
                backgroundSize: "cover",
                overflow: "hidden",
              }}
            />
          </Fade>
          <Box mt={5}>
            <Typography className={classes.teamName} variant="subtitle1">
              {team["name"]}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography
              color="primary"
              className={classes.teamRole}
              variant="subtitle2"
            >
              {team["role"]}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.teamDescription} variant="body2">
              {team["description"]}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
