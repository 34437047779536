import 'fontsource-roboto';

import {
    createMuiTheme,
    responsiveFontSizes,
    breakpoints
} from '@material-ui/core/styles';

let theme = createMuiTheme({
    typography: {
        fontFamily: "Roboto",
        h1: {
            fontWeight: "900",
            fontSize: "3.5rem",
            fontStyle: "normal",
        },
        h2: {
            fontStyle: 'normal',
            fontWeight: "900",
            fontSize: "2.5rem",
        },
        body1: {
            lineHeight: "1.8rem"
        },
        body2: {
            lineHeight: "1.8rem"
        },
        subtitle1: {
            fontSize: "1.3rem",
            fontStyle: "normal",
            fontWeight: "bold",
            letterSpacing: "0em",
        },
        subtitle2: {
            fontSize: "1rem",
            fontStyle: "italic",
            letterSpacing: "0em",
            fontWeight: "bold",
        }
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#5474AA",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#64BA9B",
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
})

theme = responsiveFontSizes(theme);

export default theme;