import React from "react";
import { Box, Typography, IconButton,Fade,Link,Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: "100%",
    padding: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: "#000000",
    position:"relative",
    bottom:0
  },
  rights: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#979797",
  },
  logo: {
    height: theme.spacing(5),
  },
  socialLink: {
    color: "white",
  },
  socialMedia:{
    [theme.breakpoints.up("md")]: {
      textAlign:"right"
    },
  }
}));

export default function Footer({logo}) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Box
        display="flex"
        alignItems="center"
        alignContent="flex-start"
      >
        <Container>
          <Grid container>
            <Grid item md={10} xs={12}>
              <Fade timeout={800} in={true}>
                <img className={classes.logo} src={logo} alt="logo" />
              </Fade>
            </Grid>
            <Grid item xs={12} md={2}>
              <Box className={classes.socialMedia}>
                <IconButton
                  href="https://www.linkedin.com/company/nanoz.cv"
                  target="_blank"
                  className={classes.socialLink}
                  aria-label="linkedIn"
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/nanoz.cv"
                  target="_blank"
                  className={classes.socialLink}
                  aria-label="facebook"
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  href="https://www.instagram.com/nanoz.cv"
                  target="_blank"
                  className={classes.socialLink}
                  aria-label="instagram"
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  href="https://twitter.com/nanoz.cv"
                  target="_blank"
                  className={classes.socialLink}
                  aria-label="twitter"
                >
                  <TwitterIcon />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography
                align="justify"
                className={classes.rights}
                variant="h6"
              >
                Copyright © {new Date().getFullYear()} Nanoz - Tecnologia e Inovação, LDA. All rights reserved. <Link color="secondary" href="/privacy" target="_blank">Política de privacidade.</Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
}
