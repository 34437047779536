import React from "react"

import productGraphic from "../../assets/img/graphics/bounds.svg"
import { Fade, Grid, Typography, Box } from "@material-ui/core"
import {  makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(4),
  },
  productGraphic: {
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  title: {},
  grid: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
}));

export default function HowCanWeHelp() {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={8}
      direction="row"
      alignItems="center"
      justify="center"
      className={classes.grid}
    >
      <Grid item md={6} sm={9} xs={12}>
        <Box mt={5}>
          <Fade timeout={800} in={true}>
            <img
              className={classes.productGraphic}
              src={productGraphic}
              alt="product"
            />
          </Fade>
        </Box>
      </Grid>
      <Grid item md={6} sm={9} xs={12}>
        <Typography color="primary" className={classes.title} variant="h2">
          Como Podemos Ajudar?
        </Typography>
        <Typography
          align="justify"
          className={classes.description}
          variant="body1"
        >
          A <b>NANOZ</b> pretende criar uma mercado online que permite os caboverdianos no país e na diáspora terem uma presença forte na internet para dinamizar o seu negócio, podendo estes ser, uma loja virtual, um serviço de entrega, serviço de reparação e manutenção de casas e eletrônicos, entre outros serviços. Viabilizando efetuar compras e receber pagamentos na hora e para qualquer pessoa, através do acesso a um ambiente seguro, de confiança, a um custo reduzido e sem ter de se preocupar com a base tecnológica que suporta todos os processos envolvidos.
        </Typography>
      </Grid>
    </Grid>
  );
}
