import React, { useContext, useState,Fragment } from "react";

import { Container, Box,Fade,Typography,Grid,Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SurveyComponent from "./SurveyComponent";
import SurveyProvider, { SurveyContext } from "./context/SurveyContext";

import PrivacyCheckBox from './PrivacyCheckBox';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  description: {
    marginTop: theme.spacing(4),
  },
  surveyContent: {
    minHeight: "100vh",
    display:"flex"
  }
}));

const Survey = () => {
  const classes = useStyles();
  const { surveyData } = useContext(SurveyContext);
const [isStarted, setStart] = useState(false);
  const onComplete = ({data}) => {
    console.log("SUbmit",{data});
  };
  return (
    <Box minHeight="100vh">
      {!isStarted && <Fade timeout={800} in={!isStarted}> 
        <Box mt={10}>
          <Container  className={classes.container}>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item sm={8}>
                <Typography align="center" color="primary" className={classes.title} variant="h2">
                  Como Participar?
                </Typography>
                <Typography
                  align="center"
                  className={classes.description}
                  variant="body1"
                >
                  Neste exato momento a nossa equipa está empenhada em desenvolver a plataforma tecnológica que promete nos apoiar no dia dia, num contexto onde a disponibilidade e o acesso a produtos, serviços, informações úteis, bem como meios de promover o melhor de nós se torna crucial para aumentarmos a equidade e integração social  criando mais e melhores oportunidades para todos.
                </Typography>
                <Typography
                  align="center"
                  className={classes.description}
                  variant="body1"
                >
                  Para podermos concretizá-lo juntos, precisamos da tua ajuda em identificar o que temos em comum, quais as nossas dificuldades e desafios, bem como as oportunidades que podemos aproveitar para criarmos uma solução que seja uma mais valia para ti e para todos, quer sejas um vendedor ambulante, um mestre de doces e salgados, um talentoso faz-tudo, ou um guru da informática.
                </Typography>
                <Box mt={5}>
                <Typography
                  align="center"
                  variant="subtitle2"
                >
                    As informações que recolhemos tem como utilidade identificarmos as áreas de serviços que mais precisam de um apoio, e se uma plataforma tecnológica seria uma ótima solução para os nossos desafios.
                </Typography>
                <PrivacyCheckBox setStart={setStart} />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Fade>}
      {isStarted && <Fade timeout={1000} in={isStarted}> 
        <Box mt={10}>
          <Container>
            <Grid
              container
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item sm={8}>
                <SurveyComponent json={surveyData} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Fade>}
    </Box>
  );
};

export default {
  title: "Start",
  path: "/start",
  isNav: true,
  islogoWhite:false,
  Container: () => (
    <SurveyProvider>
      <Survey />
    </SurveyProvider>
  ),
};
