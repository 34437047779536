import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import {
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerTop: {
    backgroundColor: "transparent",
    transition: "background-color 0.4s ease",
    // boxShadow: "0px 0px 50px rgba(84, 116, 170, 0.1)"
  },
  header: {
    backgroundColor: "white",
    transition: "background-color 0.4s ease",
    boxShadow: "0px 0px 50px rgba(84, 116, 170, 0.1)"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  button: {
    background:
      "linear-gradient(59.04deg, #65C299 -0.35%, #65BE99 12.46%, #62B59D 27.88%, #5EA8A2 44.64%, #5A94A7 62.34%, #557CA9 80.8%, #4F60AC 99.65%)",
    border: 0,
    borderRadius: 100,
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 50,
    width: 152,
    lineHeight: "19px",
  },
  logo: {
    height: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(7),
    },
  },
  buttonText: {},
}));

export default function ButtonAppBar({path, logo, logoWhite,isNav,triggers=[] }) {
  console.log({triggers,path, logo, isNav,logoWhite})
  const classes = useStyles();
  const [isTop, setTop] = useState(window.scrollY < 50);
  
  const changeNavColor = () => {
    if (window.scrollY >= 50) {
      setTop(false);
    } else {
      setTop(true);
    }
  };

  window.addEventListener("scroll", changeNavColor);
  
  const logoWhiteComponent = (
    <Fade timeout={800} in={true}>
      <img className={classes.logo} src={logoWhite} alt="logo" />
    </Fade>
  );
  const logoComponent = (
    <Fade timeout={800} in={true}>
      <img className={classes.logo} src={logo} alt="logo" />
    </Fade>
  );
  return (
    <AppBar
      className={isTop && isNav ? classes.headerTop : classes.header}
      elevation={0}
      position="fixed"
    >
      <Container>
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            {isTop && isNav && logoWhiteComponent}
            {(!isTop || !isNav) && logoComponent}
          </Link>
          <Typography variant="h6" className={classes.title}></Typography>
          {triggers.map((trigger,key)=><trigger.Component key={key} />)}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
