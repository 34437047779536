export default {
    showProgressBar: "bottom",
    "locale": "pt",
    "pages": [{
            "name": "page1",
            "elements": [{
                "type": "radiogroup",
                "name": "question1",
                "title": "Qual é a sua faixa etaria?",
                "isRequired": true,
                "choices": [
                    "16 a 20 anos",
                    "21 a 30 anos",
                    "31 a 40 anos",
                    "41 a 50 anos",
                    "51 anos ou mais"
                ]
            }]
        },
        {
            "name": "page2",
            "elements": [{
                "type": "radiogroup",
                "name": "question2",
                "title": "Qual sexo?",
                "isRequired": true,
                "choices": [{
                        "value": "item1",
                        "text": "Masculino"
                    },
                    {
                        "value": "item2",
                        "text": "Feminino"
                    }
                ]
            }]
        },
        {
            "name": "page3",
            "elements": [{
                "type": "dropdown",
                "name": "question3",
                "title": "Em qual cidade vive?",
                "isRequired": true,
                "choices": [
                    "Assomada",
                    "Calheta de São Miguel",
                    "Cidade Velha",
                    "Cova Figueira",
                    "Espargos",
                    "João Teves",
                    "Mindelo",
                    "Mosteiros",
                    "Nova Sintra",
                    "Paúl",
                    "Pedra Badejo",
                    "Picos",
                    "Ponta do Sol",
                    "Porto Inglês",
                    "Porto Novo",
                    "Praia",
                    "Ribeira Brava",
                    "Ribeira Grande",
                    "Sal Rei",
                    "Santa Maria",
                    "São Domingos",
                    "São Filipe",
                    "Tarrafal de São Nicolau",
                    "Tarrafal"
                ],
                "optionsCaption": "Escolha uma cidade"
            }]
        },
        {
            "name": "page4",
            "elements": [{
                "type": "multiple-dropdown",
                "name": "question4",
                "title": "Das atividades seguintes escolha qual já desempenhou ou tem interesse",
                "validators": [{
                    "type": "answercount",
                    "minCount": 1
                }],
                "hasComment": true,
                "commentText": "Outras atividades (descreve)",
                "choices": [
                    "Reparação e manutenção de computadores e de equipamentos perifericos",
                    "Serviços domesticos",
                    "Serviços de mudança",
                    "Canalização",
                    "Pintura de interior e exterior",
                    "Serviços de Carpintaria e serralheria",
                    "Estetica e outros serviços de cuidados com a beleza",
                    "Aluguel de materiais para decoração de festas",
                    "Aluguel de casa de festas",
                    "Produção de eventos",
                    "Aluguel de moveis, utensilios e aparelhos de uso domestico e pessoal",
                    "Fisioterapia",
                    "Enfermagem",
                    "Nutricionista",
                    "Personal Trainer",
                    "Consultorio odontologico",
                    "Veterinario",
                    "Prestacao de servicos de arquitetura,construcoes e atividades paisagisticas",
                    "Pedreiro/servente",
                    "Instalação e manutenção eletrica",
                    "Comercio de materiais para construcao civil",
                    "Aluguel de maquinas e equipamentos para construção civil",
                    "Limpeza em predios e em domicilios",
                    "Jardinagem",
                    "Serviço de pulverização e controle de pragas",
                    "Atividades de ensino",
                    "Serviços de restauração",
                    "Serviços de hospedagem, turismo, viagens",
                    "Guias de turismo",
                    "Agenciamento marítimo",
                    "Venda de Bilhetes",
                    "Vigilância e segurança privada",
                    "Parques de diversões, centros de lazer",
                    "Fotografia e produção de conteúdos",
                    "Impressao de produtos graficos",
                    "Estamparia e texturização em fios, tecidos, artefatos texteis e peças do vestuario",
                    "Reparação e manutenção de veiculos",
                    "Serviços de reboque de veiculos",
                    "Serviços de encadernação e plastificação",
                    "Costureira/alfaite/modista",
                    "Auxiliar de cozinha",
                    "Serviço de transporte de passageiros",
                    "Serviços de entrega rapida",
                    "Prestacao de servicos de escritorio",
                    "Propaganda e publicidade, inclusive promoção de vendas, planejamento de campanhas"
                ],
                "choicesOrder": "asc",
                "placeholder": "Escolha uma ou mais atividades"
            }]
        },
        {
            "name": "page5",
            "elements": [{
                    "type": "radiogroup",
                    "name": "question5",
                    "title": "Em que situação laboral melhor se enquadra atualmente",
                    "choices": [
                        "Trabalhador por conta própria",
                        "Estudante",
                        "Trabalhador na administração pública",
                        "Trabalhador de uma empresa privada",
                        "Empregador",
                        "Trabalhador doméstico",
                        "Trabalhador familiar sem remuneração",
                        "Desempregado"
                    ],
                    "choicesOrder": "random",
                    "hasOther": true,
                    "otherText": "Outro (descreve)"
                },
                {
                    "type": "radiogroup",
                    "name": "question7",
                    "visibleIf": "{question5} = 'Desempregado'",
                    "title": "Qual o motivo de estar desempregado?",
                    "choices": [
                        "Vontade própria",
                        "Relacionado com idade/Reforma",
                        "Suspensão de actividade da entidade empregadora",
                        "Redução da actividade",
                        "Fim de contrato"
                    ],
                    "choicesOrder": "random",
                    "hasOther": true,
                    "otherText": "Outro (descreve)"
                }
            ]
        },
        {
            "name": "page6",
            "elements": [{
                "type": "checkbox",
                "name": "question6",
                "title": "Das opções abaixo, qual utiliza para divulgar ou vender o seu produto ou serviço?",
                "validators": [{
                    "type": "answercount",
                    "maxCount": 3
                }],
                "choices": [
                    "Facebook",
                    "Instagram",
                    "Aplicativos de chat",
                    {
                        "value": "Twiter",
                        "text": "Twitter"
                    },
                    "Website ou aplicativo próprio",
                    "Espaço comercial",
                    "Em casa"
                ],
                "choicesOrder": "random",
                "hasOther": true,
                "otherPlaceHolder": "sd",
                "otherText": "Outro (descvreve)"
            }],
            "visibleIf": "{question5} anyof ['Trabalhador doméstico', 'Trabalhador familiar sem remuneração', 'Empregador', 'Trabalhador por conta própria', 'Trabalhador de uma empresa privada', 'other']"
        },
        {
            "name": "page7",
            "elements": [{
                "type": "checkbox",
                "name": "question8",
                "title": "Das opções abaixo, qual mais utiliza para procurar ou comprar um produto ou serviço?",
                "validators": [{
                    "type": "answercount",
                    "maxCount": 3
                }],
                "choices": [
                    "Facebook",
                    "Instagram",
                    "Aplicativos de chat",
                    "Website ou aplicativo do comerciante",
                    "Espaço comercial"
                ],
                "hasOther": true,
                "otherText": "Outro (descreve)"
            }]
        },
        {
            "name": "page8",
            "elements": [{
                "type": "multiple-dropdown",
                "name": "question9",
                "title": "Das plataformas e serviços de Cabo Verde baseados na internet, quais já utilizou.",
                "hasComment": true,
                "commentText": "Outro (não incluido na lista)",
                "choices": [
                    "KumpraLi",
                    "Wegou",
                    "Compra Com Garantia",
                    "Agrodimeu",
                    "Popas Poupança",
                    "Empregos.cv",
                    "Formação.cv",
                    "Vunku CV",
                    "Nu Bai",
                    "Yellow Delivery",
                    "Taxi Ja",
                    "Ifome",
                    "Djunto Cabo Verde",
                    "Gilera Cheio"
                ],
                "placeholder": "Escolha os serviços que já utilizaste"
            }]
        },
        {
            "name": "page9",
            "elements": [{
                "type": "barrating",
                "name": "question10",
                "title": "De uma forma geral como avalia os serviços baseados na internet em Cabo Verde?",
                "choices": [
                    1,
                    2,
                    3,
                    4,
                    5
                ]
            }]
        },
        {
            "name": "page10",
            "elements": [{
                    "type": "matrixdropdown",
                    "name": "question11",
                    "title": "Das opções abaixo, classifica de acordo com o que mais gosta ou mais o incomoda nas plataformas de compra e venda de produtos e serviços baseados na internet em Cabo Verde",
                    "columns": [{
                        cellType: "rating",
                        showInMultipleColumns: true,
                        isRequired: true,
                        choices: [
                            1, 2, 3, 4, 5
                        ]
                    }],
                    "rows": [
                        "Econtrar produtos ou serviços que necessita",
                        "Controlo do estado e localização de um produto ou serviço",
                        "Diversidade de produtos e serviços disponibilizados",
                        "Publicidade de produtos e serviços",
                        "Comunicação em tempo real com outras pessoas",
                        "Pagamentos com cartão multibanco",
                        "Controlo de custos",
                        "Facilidade de uso"
                    ]
                }
            ],
            "visibleIf": "{question10} notempty"
        },
        {
            "name": "page11",
            "elements": [
                
                {
                    "name": "page11",
                    "elements": [{
                        "type": "matrixdropdown",
                        "name": "question13",
                        "title": "Qual o nível de importancia cada uma dessas funcionalidades apresentam para si?",
                        "columns": [{
                            cellType: "rating",
                            showInMultipleColumns: true,
                            isRequired: true,
                            choices: [
                                1,2,3,4,5
                            ]
                        }],
                        "rows": [
                            "Chat no aplicativo",
                            "Avaliação e feedback",
                            "Pagamentos no aplicativo",
                            "Criação de uma lista de serviços realizados (Currículo/Portfolio).",
                            "Agendamento de serviços",
                            "Dashboard (painel de métricas e indicadores importantes)",
                            "Pesquisa avançada de items",
                            "Notificação via dispositivo",
                            "Ajuda e suporte técnico",
                            "Localização de items e pessoas no mapa"
                        ]
                    }]
                }
            ]
        }
    ]
};