import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box,Typography } from '@material-ui/core';
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PaymentIcon from "@material-ui/icons/Payment";
import PinDropIcon from "@material-ui/icons/PinDrop";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ChatIcon from "@material-ui/icons/Chat";
import DashboardIcon from "@material-ui/icons/Dashboard";
const useStyles = makeStyles((theme) => ({
  grid: {
    padding:"0 20px",
    flexDirection:'row',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  name: {
    marginLeft: 10
  }
}));

export default function AutoGrid() {
  const classes = useStyles();

  return (
    <Box flexGrow={1} mt={6}>
      <Grid
        className={classes.grid}
        justify="space-evenly"
        container
        spacing={2}
      >
        <Grid item>
          <Box display="flex">
            <ChatIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Chat Criptografado
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <ThumbUpIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Avaliação e Feedback
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <DashboardIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Painéis Interativos
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <NotificationsActiveIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Notificações Instantâneas
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <LocalOfferIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Categorias De Produtos e Serviços
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <PinDropIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Localização De Items e Pessoas No Mapa
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <PaymentIcon color="secondary" fontSize="large" />
            <Typography align="left" className={classes.name} variant="body1">
              Diversos Métodos de Pagamento
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
