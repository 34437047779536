// @flow
import React, { createContext } from "react";
import {
  fireFunction,
  logEvent
} from "../firebase";

const defaultAppContext = {
    user:undefined,
    subscribe:(data)=>{
      return fireFunction('addSubscriptionEmail', data);
    },
    logEvent
};

const AppContext = createContext(defaultAppContext);

const AppProvider = ({children})=>(
  <AppContext.Provider value={defaultAppContext}>
        {children}
  </AppContext.Provider>
)

export default AppContext;

export {
  AppProvider
}
