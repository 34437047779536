import React, { Fragment,useEffect  } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function AppShell({ NavBar,footer, containers, theme, rootRoute = "/", navtype = "btnav"}) {
  return (
    <MuiThemeProvider theme={theme}>
      <Fragment>
        <Router>
          <Switch>
            {containers.map(({ path, Container, isNav,islogoWhite=false,triggers=[] }, index) => (
              <Route
                key={index}
                exact={path === "/" ? true : false}
                path={path}
              >
                <ScrollToTop>
                  <NavBar isNav={isNav} islogoWhite={islogoWhite} triggers={triggers} />
                  <Container />
                  {footer}
                </ScrollToTop>
              </Route>
            ))}
            <Redirect from="/" to={rootRoute} />
          </Switch>
        </Router>
      </Fragment>
    </MuiThemeProvider>
  );
}

export default AppShell;
