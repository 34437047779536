import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Checkbox,Typography, Link, Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    alignSelf: "center",
    border: 0,
    borderRadius: 100,
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 50,
    width: 152,
    lineHeight: "19px",
  },
}));

export default function CheckboxesGroup({setStart}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    checked: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { checked } = state;
  const error = [checked].filter((v) => v).length !== 1;

  return (
    <div className={classes.root}>
      <FormControl required error={error} component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} name="checked" />}
            label={
                <Typography className={classes.privacy} variant="caption">
                    Eu concordo com os
                    <Link color="secondary" href="/terms" target="_blank" style={{paddingLeft: 5}}>Termos e Condições </Link>
                    e a 
                    <Link color="secondary" href="/privacy" target="_blank" style={{paddingLeft: 5}}>Política de Privacidade</Link>
              </Typography>}
          />
            <Button
                color="secondary"
                disabled={error ? true: false}
                variant="contained"
                onClick={(event)=>{
                    if(checked){
                        setStart(checked);
                    }
                }}
                className={classes.button}
                >
                Iniciar
            </Button>
        </FormGroup>
      </FormControl>
    </div>
  );
}