import React from "react";
import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";
import "survey-react/modern.min.css";

import "jquery-ui/themes/base/all.css";
//import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
//import "bootstrap-slider/dist/css/bootstrap-slider.css";

import "jquery-bar-rating/dist/themes/css-stars.css";

import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";

import "pretty-checkbox/dist/pretty-checkbox.css";

//import "icheck/skins/square/blue.css";
window["$"] = window["jQuery"] = $;
//require("icheck");


var defaultThemeColors = Survey.StylesManager.ThemeColors["modern"];

defaultThemeColors["$main-color"] = "#5474AA"; //purple- the theme's underlines and button'
defaultThemeColors["$main-hover-color"] = "#64BA9B";
defaultThemeColors["$header-color"] = "#7ff07f";
defaultThemeColors["$answer-background-color"] = "rgb(84 116 170 / 26%)";


defaultThemeColors["$header-background-color"] = "#4a4a4a";
defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
defaultThemeColors["$font-family"] = "Roboto";


Survey.StylesManager.applyTheme("modern");

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
//widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
//widgets.bootstrapslider(Survey);

export default function SurveyPage({
  json,
  onComplete = (result) => console.log("Complete! " + result),
  onValueChanged = (result) => console.log("Changed! " + result),
}) {
  var model = new Survey.Model(json);
  return (
    <Survey.Survey
      model={model}
      onComplete={onComplete}
      onValueChanged={onValueChanged}
    />
  );
}
