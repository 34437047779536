import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Box,
  Link,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Formik} from "formik";
import clsx from "clsx";
import SendIcon from "@material-ui/icons/Send";

import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  background: {
    background: "white",
    padding: 10,
    paddingRight: 3,
    paddingLeft: theme.spacing(4),
  },
  helpertext: {
    paddingLeft: theme.spacing(4),
    textAlign: "center",
  },
  alert: {
    borderRadius: "100px",
  },
  privacy: {
    color: "white",
  },
}));

const schema = yup.object().shape({
  name: yup.string(),
  email: yup
    .string()
    .email("Introduza um endereço de email válido")
    .required("Para receber por email as nossas novidades, deve introduzir um endereço de email"),
});

const defaultValue = { email: ''};
const defaultStatus = { success: true };

export default function SubscriptionEmailInput({ subscribe, logEvent }) {
  const classes = useStyles();
  const [status, setStatus] = useState(defaultStatus);
  return (
    <Formik
      initialValues={defaultValue}
      onSubmit={(
        values,
        { setSubmitting, setValues, resetForm, setErrors }
      ) => {
        logEvent('subscribing');
        subscribe(values)
          .then(({ data }) => {
            console.log({ data });
            setStatus({ success: data.message });
            resetForm();
          })
          .catch((err) => {
            setErrors({
              email: "Não foi possivel submeter a subscrição, tente mais tarde",
            });
            logEvent('errorSubscribing',err.data);
          })
          .finally(() => {
            setSubmitting(false)
            logEvent('subscribed');
          });
      }}
      validationSchema={schema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => {
        console.log({ status });
        return (
          <FormControl fullWidth error={errors.email && touched.email}>
            <Box
              mb={1}
              borderRadius="100px"
              className={clsx(classes.background)}
            >
              <Input
                fullWidth
                aria-describedby="component-helper-text"
                type="email"
                name="email"
                value={values.email}
                placeholder="Digite seu e-mail"
                onBlur={(event)=>{
                  logEvent('onEmailInputBlur', values);
                  handleBlur(event);
                }}
                onChange={(event) => {
                  handleChange(event);
                  setStatus(defaultStatus);
                }}
                disableUnderline={true}
                disabled={isSubmitting}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color="primary"
                      disabled={errors.email? true: false}
                      onClick={handleSubmit}
                    >
                      {isSubmitting && <CircularProgress size={22} />}
                      {!isSubmitting && <SendIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            {((touched.email && errors.email) || status.success!==true) && (
              <Alert
                className={classes.alert}
                severity={touched.email && errors.email ? "error" : "success"}
              >
                {errors.email || status.success}
              </Alert>
            )}
            <Box fontStyle="italic">
              <Typography className={classes.privacy} variant="caption">
                A sua privacidade é importante para nós,consulte a nossa
                <Link color="secondary" href="/privacy" target="_blank" style={{paddingLeft: 5}}>Política de Privacidade</Link>.
              </Typography>
            </Box>
          </FormControl>
        );
      }}
    </Formik>
  );
}
