import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import MessengerCustomerChat from 'react-messenger-customer-chat';

ReactDOM.render(
  <React.Fragment>
    <MessengerCustomerChat
      pageId="103059834601864"
      appId="1678638095724206"
      language="pt_PT"
      themeColor="#64BA9B"
      shouldShowDialog={true}
      greetingDialogDisplay="fade"
    />
    <App />
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
