import React from "react";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TeamCards from "./teamCards";

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(4),
  },
}));

export default function TeamSection() {
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Typography
          color="primary"
          align="center"
          variant="h2"
        >
          A Nossa Equipa.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography align="center" variant="body1">
          Jovens que representam a chave para o nosso sucesso, já com muitos anos de experiência e dedicação absoluta no que fazem.
        </Typography>
      </Box>
      <Box mt={5}>
        <TeamCards />
      </Box>
    </Box>
  );
}
